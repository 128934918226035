import axios, { AxiosInstance } from 'axios'
import { ITaskData } from './IApiClient'
import getBrowserFingerprint from 'get-browser-fingerprint'
import { ethers } from 'ethers'
import { Buffer } from 'buffer'

export class ApiClient {
  private axios: AxiosInstance

  constructor() {
    this.axios = this.createAxiosInstance()
  }

  async createTask(prompt: string, captcha: string): Promise<string> {
    const fingerprint = this.getBrowserFingerprint()
    const fingerprintHash = this.getFingerprintHash(fingerprint, captcha)

    const known = this.knownModels.find((km) => `__${km}` === prompt)
    if (known) return prompt

    const res = await this.axios.post(
      '/v1/tasks',
      {
        prompt,
      },
      {
        headers: {
          'x-captcha-token': captcha,
          'x-fingerprint-value': fingerprint.toString(16),
          'x-fingerprint-hash': fingerprintHash,
        },
      },
    )

    return res.data.result ?? null
  }

  async getTask(id: string): Promise<ITaskData> {
    if (id.slice(0, 2) === '__') {
      return {
        id,
        seed: 0,
        modelUrl: `${window.location.origin}/assets/${id.replace('__', '')}.glb`,
        createdAt: 'null',
      } as any
    }

    const res = await this.axios.get(`/v1/tasks/${id}`)

    return res.data
  }

  async waitForTaskResolved(id: string, onProgressChanged: (progress: number) => void) {
    if (id.slice(0, 2) === '__') {
      onProgressChanged(100)
      return true
    }

    return await this.waitUntilCondition(
      async () => {
        const progress = await this.getProgress(id)
        onProgressChanged(progress ?? 0)
        return progress === 100
      },
      4000,
      120000,
    )
  }

  async delay(timeout: number) {
    return await new Promise((resolve) => {
      setTimeout(resolve, timeout)
    })
  }

  private async getProgress(id: string) {
    return await this.getTask(id).then((data) => data.progress)
  }

  private async waitUntilCondition(
    cond: () => Promise<boolean>,
    interval: number,
    timeout: number,
  ) {
    const start = Date.now()

    while (true) {
      if (await cond()) return true

      const diff = Date.now() - start
      if (diff > timeout) return false

      await this.delay(interval)
    }
  }

  private createAxiosInstance() {
    return axios.create({
      baseURL: 'https://ai-3d-api-bkjks.ondigitalocean.app',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'xv2V6wrsku4Ffgu4q2eb/wLOJP1H0rih4MWSBuguTUE=',
      },
    })
  }

  private get knownModels() {
    return [
      'christmas_tree',
      'gold_coin',
      'hamster',
      'knight',
      'llama',
      'parrot',
      'dog',
      'frog',
    ]
  }

  private getFingerprintHash(fingerprint: number, captcha: string) {
    const buff = Buffer.from(`${captcha}__${fingerprint.toString(16)}`)
    return ethers.hexlify(ethers.sha256(buff))
  }

  private getBrowserFingerprint(): number {
    try {
      const val = getBrowserFingerprint()
      if (!val) throw new Error('empty value returned')

      return val
    } catch (e) {
      console.warn(e)
      return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
    }
  }
}
