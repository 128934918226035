import { Typography } from '@mui/material'

export interface PropertyItemProps {
  label: string
  value?: string | number | null
  labelWidth: number
}

const PropertyItem: React.FC<PropertyItemProps> = (props) => {
  return (
    <Typography variant="body2" gutterBottom>
      <span
        style={{
          display: 'inline-block',
          width: props.labelWidth,
          fontWeight: 700,
        }}
      >
        {props.label}:
      </span>
      <span style={{ color: '#ccc' }}>{props.value ?? 'unknown'}</span>
    </Typography>
  )
}

export default PropertyItem
