import React from 'react'

export const useTimer = () => {
  const [endsAt, setEndsAt] = React.useState(0)
  const [secondsLeft, setSecondsLeft] = React.useState(0)

  const getSecondsLeft = React.useCallback((endsAt: number, currentTime?: number) => {
    return Math.max(Math.ceil((endsAt - (currentTime ?? Date.now())) / 1000), 0)
  }, [])

  React.useEffect(() => {
    if (!endsAt) return

    const handle = setInterval(() => {
      const time = Date.now()

      setSecondsLeft(getSecondsLeft(endsAt, time))

      if (time >= endsAt) {
        clearInterval(handle)

        setEndsAt(0)
        setSecondsLeft(0)
      }
    }, 1000)

    return () => clearInterval(handle)
  }, [endsAt, getSecondsLeft])

  const start = (seconds: number) => {
    if (endsAt) return console.warn('Timer already running')

    const time = Date.now()
    const end = time + seconds * 1000

    setEndsAt(end)
    setSecondsLeft(getSecondsLeft(end, time))
  }

  return { secondsLeft, start }
}
