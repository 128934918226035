import axios from 'axios'

export class ModelCache {
  private data: Record<string, ArrayBuffer> = {}

  async get(url: string) {
    if (this.data[url]) return this.data[url]

    this.data[url] = await axios
      .get(url, { responseType: 'arraybuffer' })
      .then((res) => res.data)
      .catch((err) => {
        console.error(`Failed to fetch model (${url})`)
        return null
      })

    return this.data[url]
  }
}
