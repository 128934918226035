import React from 'react'
import { ModelRenderer } from './ModelRenderer'
import { Box, IconButton, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ModelCache } from './ModelCache'

export interface ModelModalProps {
  url: string
  open: boolean
  onClose: () => void
  cache?: ModelCache
}

const ModelModal: React.FC<ModelModalProps> = (props: ModelModalProps) => {
  const ref = React.useRef<HTMLCanvasElement>(null)

  const getCanvasParentSize = () => {
    const parentStyle = getComputedStyle(ref.current!.parentElement!)

    const width = Number(parentStyle.width?.replace('px', '') ?? 0)
    const height = Number(parentStyle.height?.replace('px', '') ?? 0)

    return { width, height }
  }

  React.useEffect(() => {
    if (!ref.current || !props.open) return

    const { width, height } = getCanvasParentSize()

    const renderer = new ModelRenderer({
      url: props.url,
      canvas: ref.current,
      enableControls: true,
      cache: props.cache,
      displayGrid: true,
      initialWidth: width,
      initialHeight: height,
    })

    const resizeCallback = () => {
      if (!ref.current) return

      const { width, height } = getCanvasParentSize()

      ref.current.width = width
      ref.current.height = height

      renderer.updateSize(width, height)
    }

    window.addEventListener('resize', resizeCallback)

    return () => {
      renderer.destroy()
      window.removeEventListener('resize', resizeCallback)
    }
  }, [props.url, props.open, props.cache])

  return (
    <Modal open={props.open} onClose={props.onClose} keepMounted>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          maxWidth: '1000px',
          maxHeight: '1000px',
          backgroundColor: '#1e1e1e',
          borderRadius: 4,
          overflow: 'hidden',
          outline: 'none',
        }}
      >
        <IconButton
          size="large"
          sx={{ position: 'absolute', right: '10px', top: '10px' }}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <canvas ref={ref} width="100%" height="100%" />
      </Box>
    </Modal>
  )
}

export default ModelModal
