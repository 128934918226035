import * as THREE from 'three'
import { GLTF, GLTFLoader } from 'three/examples/jsm/Addons'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { ModelCache } from './ModelCache'

export interface ModelRendererOptions {
  canvas: HTMLCanvasElement
  url: string
  initialWidth: number
  initialHeight: number
  cache?: ModelCache
  enableRotation?: boolean
  enableControls?: boolean
  backgroundColor?: string
  displayGrid?: boolean
}

export class ModelRenderer {
  private width: number
  private height: number

  private lastFrame = 0
  private delta = 0
  private animationHandleId?: number

  private scene: THREE.Scene
  private camera: THREE.PerspectiveCamera
  private renderer: THREE.WebGLRenderer
  private controls?: OrbitControls
  private model?: THREE.Object3D<THREE.Object3DEventMap>

  constructor(private opts: ModelRendererOptions) {
    this.width = opts.initialWidth
    this.height = opts.initialHeight

    this.scene = this.setupScene()
    this.camera = this.setupCamera()
    this.renderer = this.setupRenderer()

    if (this.opts.enableControls) {
      this.controls = this.setupControls()
    }

    this.setupLights()

    this.render(0)
  }

  updateSize(width: number, height: number) {
    this.width = width
    this.height = height

    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(this.width, this.height)
  }

  destroy() {
    if (this.animationHandleId) {
      window.cancelAnimationFrame(this.animationHandleId)
    }
  }

  private render(time: number) {
    this.delta = time - this.lastFrame
    this.lastFrame = time

    if (this.controls) {
      this.controls.update(this.delta / 1000)
    }

    if (this.opts.enableRotation) {
      this.model?.rotateY(0.0008 * this.delta)
    }

    this.renderer.render(this.scene, this.camera)

    this.animationHandleId = window.requestAnimationFrame((time) => this.render(time))
  }

  private setupScene() {
    const scene = new THREE.Scene()
    scene.background = new THREE.Color(this.opts.backgroundColor ?? 0x1e1e1e)

    const loader = new GLTFLoader()

    const onLoaded = (gltf: GLTF) => {
      this.setupGrid(gltf.scene)

      scene.add(gltf.scene)
      gltf.scene.rotateY(Math.PI * 1.32)
      this.model = gltf.scene
    }

    if (this.opts.cache) {
      this.opts.cache.get(this.opts.url).then((buffer) => {
        if (buffer) {
          loader.parse(buffer, '/', onLoaded)
        }
      })
    } else {
      loader.load(this.opts.url, onLoaded, undefined, console.error)
    }

    return scene
  }

  private setupGrid(loadedScene: THREE.Group<THREE.Object3DEventMap>) {
    if (!this.opts.displayGrid) return

    const color = new THREE.Color(0x444444)

    const grid = new THREE.GridHelper(10, 30, color, color)

    grid.position.y =
      (loadedScene?.children as any)?.[0]?.geometry?.boundingBox?.min?.y || -0.5

    this.scene.add(grid)
  }

  private setupLights() {
    const baseIntensity = 15

    const lights = [
      { intensity: 1, pos: [1, 1.5, 2] },
      { intensity: 0.5, pos: [-2, 0.5, 2] },
      { intensity: 0.2, pos: [-1, 1.5, -1.5] },
    ]

    for (const light of lights) {
      const [x, y, z] = light.pos

      const l = new THREE.PointLight(0xffffff, light.intensity * baseIntensity)
      l.position.set(x, y, z)
      this.scene.add(l)
    }

    const ambient = new THREE.AmbientLight(0xffffff, baseIntensity / 20)
    this.scene.add(ambient)
  }

  private setupControls() {
    const controls = new OrbitControls(this.camera, this.renderer.domElement)
    controls.enableDamping = true

    return controls
  }

  private setupCamera() {
    const camera = new THREE.PerspectiveCamera(75, this.width / this.height, 0.1, 1000)

    camera.position.set(0, 0.8, 2)
    camera.lookAt(0, 0, 0)

    if (this.width > 200 && this.width < 300) {
      camera.zoom = 1.6
    } else if (this.width > 300 && this.width < 600) {
      camera.zoom = 0.8
    } else if (this.width > 600 && this.width < 1000) {
      camera.zoom = 0.8
    } else {
      camera.zoom = 1
    }

    camera.updateProjectionMatrix()

    return camera
  }

  private setupRenderer() {
    const renderer = new THREE.WebGLRenderer({
      canvas: this.opts.canvas,
      antialias: true,
    })
    renderer.setSize(this.width, this.height)

    return renderer
  }
}
