import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  TextField,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import DownloadIcon from '@mui/icons-material/Download'
import RefreshIcon from '@mui/icons-material/Refresh'
import React from 'react'
import { useTask } from './useTask'
import PropertyItem from './PropertyItem'
import ModelThumbnail from './ModelThumbnail'
import ModelModal from './ModelModal'
import { ModelCache } from './ModelCache'
import ReCAPTCHA from 'react-google-recaptcha'

const TaskCard: React.FC = () => {
  const captchaRef = React.useRef<ReCAPTCHA>(null)
  const modelCache = React.useRef(new ModelCache())

  const [prompt, setPrompt] = React.useState('')
  const [modal, setModal] = React.useState(false)
  const task = useTask()

  React.useEffect(() => {
    const prompt = new URLSearchParams(window.location.search).get('prompt')
    if (!prompt) return

    setPrompt(prompt)

    handleCreate(prompt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreate = async (prompt: string) => {
    if (!captchaRef.current) {
      console.warn('Missing captcha ref')
      return
    }

    const res = await captchaRef.current
      .executeAsync()
      .then((res) => (res === null ? new Error('Missing captcha token') : res))
      .catch((e) => e as Error)

    if (res instanceof Error) {
      task.forceError(res)
    } else {
      await task.startTask(prompt, res)
    }
  }

  const handleReset = () => {
    task.reset()
    captchaRef.current?.reset()
  }

  return (
    <Paper elevation={2} sx={{ padding: 3 }}>
      <ReCAPTCHA
        sitekey="6LccWYApAAAAAOjmExQZLx97f_r_-anx9zu_0hcC"
        size="invisible"
        theme="dark"
        ref={captchaRef}
      />
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} md={10}>
          <TextField
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={task.status !== 'idle'}
            variant="outlined"
            placeholder="Prompt..."
            autoComplete={'off'}
            fullWidth
            autoFocus
            onKeyDown={(e) =>
              e.key === 'Enter'
                ? document.getElementById('start-task-button')?.click()
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            id="start-task-button"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            sx={{ height: '56px' }}
            size="large"
            onClick={() => handleCreate(prompt)}
            disabled={task.status !== 'idle' || prompt === ''}
            fullWidth
          >
            Create
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 4 }}>
          <LinearProgress
            variant="buffer"
            value={task.progress}
            valueBuffer={
              task.progress === 0 ? 0 : task.progress + 1 + (task.progress % 5)
            }
            color={
              task.progress === 100 && task.status === 'success' ? 'success' : 'primary'
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box
            onClick={() => (task.status === 'success' ? setModal(true) : null)}
            sx={{
              height: '250px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#1e1e1e',
              borderRadius: 3,
              overflow: 'hidden',
              cursor: task.status === 'success' ? 'pointer' : undefined,
            }}
          >
            {task.status === 'success' && (
              <ModelThumbnail
                url={task.data?.modelUrl ?? ''}
                width={250}
                height={250}
                cache={modelCache.current}
              />
            )}
            {task.status === 'pending' && <CircularProgress size={65} />}
            {task.status === 'failed' && (
              <ReportGmailerrorredIcon color="error" sx={{ fontSize: 65 }} />
            )}
            {task.status === 'idle' && (
              <ViewInArIcon color="disabled" sx={{ fontSize: 65 }} />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {task.status === 'failed' && (
            <React.Fragment>
              <Alert variant="filled" severity="error">
                {task.errorMessage}
              </Alert>
              <br />
              <Button onClick={task.reset}>Try again</Button>
            </React.Fragment>
          )}
          {task.status === 'success' && (
            <React.Fragment>
              <Box sx={{ marginBottom: 3 }}>
                <PropertyItem label="id" value={task.id} labelWidth={85} />
                <PropertyItem label="seed" value={task.data?.seed} labelWidth={85} />
                <PropertyItem label="format" value="glb" labelWidth={85} />
                <PropertyItem
                  label="created_at"
                  value={task.data?.updatedAt}
                  labelWidth={85}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 150 }}>
                <Button
                  variant="outlined"
                  endIcon={<DownloadIcon />}
                  disabled={!task.data?.modelUrl}
                  href={task.data?.modelUrl ?? ''}
                  size="small"
                  sx={{ marginBottom: 1.5 }}
                >
                  Download
                </Button>
                <Button
                  variant="outlined"
                  endIcon={<RefreshIcon />}
                  size="small"
                  color="secondary"
                  onClick={handleReset}
                >
                  Try again
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <ModelModal
        url={task.data?.modelUrl ?? ''}
        open={modal}
        onClose={() => setModal(false)}
        cache={modelCache.current}
      />
    </Paper>
  )
}

export default TaskCard
