import React from 'react'
import { ModelRenderer } from './ModelRenderer'
import { ModelCache } from './ModelCache'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { Box } from '@mui/material'

export interface ModelThumbnailProps {
  url: string
  width: number
  height: number
  cache?: ModelCache
}

const ModelThumbnail: React.FC<ModelThumbnailProps> = (props: ModelThumbnailProps) => {
  const ref = React.useRef<HTMLCanvasElement>(null)

  React.useEffect(() => {
    if (!ref.current) return

    const renderer = new ModelRenderer({
      url: props.url,
      canvas: ref.current,
      enableRotation: true,
      cache: props.cache,
      initialWidth: props.width,
      initialHeight: props.height,
    })

    return () => renderer.destroy()
  }, [props.url, props.cache, props.width, props.height])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <canvas ref={ref} width={props.width} height={props.height} />
      <ZoomOutMapIcon
        sx={{ position: 'absolute', right: 15, top: 15 }}
        color="disabled"
      />
    </Box>
  )
}

export default ModelThumbnail
