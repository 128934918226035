import React from 'react'
import TaskCard from './TaskCard'
import { ThemeProvider } from './ThemeProvider'
import { AppBar, Box, Container } from '@mui/material'

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <AppBar
        sx={{
          height: 60,
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: 2,
          cursor: 'default',
        }}
      >
        <img src="/assets/rosnet-logo.png" alt="rosnet-logo" width={115} />
      </AppBar>
      <Box sx={{ height: 60 }} />
      <Container
        sx={(theme) => ({
          padding: 0,
          [theme.breakpoints.up('md')]: {
            paddingTop: 10,
          },
        })}
      >
        <TaskCard />
      </Container>
    </ThemeProvider>
  )
}

export default App
