import React from 'react'
import { ITaskData } from './IApiClient'
import { ApiClient } from './ApiClient'
import { inspect } from 'util'
import { AxiosError } from 'axios'
import { useTimer } from './useTimer'

export type TaskStatus = 'idle' | 'pending' | 'success' | 'failed'

export interface ITaskState {
  status: TaskStatus
  progress: number
  id: string | null
  data: ITaskData | null
  errorMessage: string | null
}

const getInitialState = (): ITaskState => ({
  status: 'idle',
  progress: 0,
  id: null,
  data: null,
  errorMessage: null,
})

export const useTask = () => {
  const [state, setState] = React.useState(getInitialState())
  const client = new ApiClient()
  const timer = useTimer()

  React.useEffect(() => {
    const progressFromTimer = (20 - (timer.secondsLeft || 20)) * 3

    setState((state) => ({
      ...state,
      progress: Math.max(state.progress, progressFromTimer),
    }))
  }, [timer.secondsLeft])

  const formatError = (e?: any) => {
    if (!e) return 'Empty error'

    if (e instanceof AxiosError && e.response?.status) {
      return `Request failed with status ${e.response?.status}`
    }

    if (e instanceof Error) return e.message

    return inspect(e)
  }

  const forceError = (e: Error) => {
    setState((s) => ({ ...s, errorMessage: formatError(e), status: 'failed' }))
  }

  const startTask = async (prompt: string, captcha: string) => {
    if (state.status !== 'idle') return

    setState((s) => ({ ...s, status: 'pending' }))

    try {
      const id = await client.createTask(prompt, captcha)

      timer.start(20)
      await client.delay(20000)

      const resolved = await client.waitForTaskResolved(id, onProgress)
      if (!resolved) throw new Error('Task timeout reached')

      const data = await client.getTask(id)

      setState((s) => ({ ...s, id, data, status: 'success' }))
    } catch (e) {
      console.error(e)

      setState((s) => ({ ...s, errorMessage: parseError(e), status: 'failed' }))
    }
  }

  const onProgress = (progress: number) => {
    setState((s) => ({ ...s, progress }))
  }

  const parseError = (e: any) => {
    if (e instanceof AxiosError && e.response?.status === 429) {
      return 'Your ip address reached limit of requests in this hour try again later'
    }

    if (
      e instanceof AxiosError &&
      e.response?.status === 403 &&
      e.response?.data?.message === 'fingerprint limit exceeded'
    ) {
      return 'You exceeded limit of 3 prompts per hour. Try again later.'
    }

    return formatError(e)
  }

  const reset = () => {
    if (!['success', 'failed'].includes(state.status)) return

    setState(getInitialState())
  }

  return { ...state, startTask, reset, forceError }
}
