import { PropsWithChildren } from 'react'
import createTheme from '@mui/material/styles/createTheme'
import { ThemeProvider as RawThemeProvider } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2196F3',
    },
    secondary: {
      main: '#F37E21',
    },
  },
})

export const ThemeProvider: React.FC<PropsWithChildren> = (props) => {
  return <RawThemeProvider theme={theme}>{props.children}</RawThemeProvider>
}
